import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import ipo1 from "../../../static/img/ipo/ipo1.jpg"
import ipo2 from "../../../static/img/ipo/ipo2.jpg"
import ipo3 from "../../../static/img/ipo/ipo3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Programa IPO"/>
            <Banner title="Programa IPO"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa del sector financiero ha conducido una
                                    suerte de
                                    colocaciones de instrumentos de deuda (cebures y eurobonos), y está dispuesta a dar
                                    un
                                    paso más allá en
                                    su estrategia de consecución de recursos, a través de un IPO, con el objetivo de
                                    fortalecer su posición
                                    de capitalización y apuntalar sus coeficientes de liquidez y endeudamiento.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={ipo1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={ipo2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>Por sus capacidades, red de contactos y alcance, se
                                    ha decidido a
                                    invitar a IRStrat como asesor clave en el proceso, a través de la contratación de su
                                    solución IPO a la
                                    medida, con el mandato de facilitar el proceso de colocación, acortar el tiempo de
                                    ejecución, y
                                    maximizar la valuación y tamaño de la colocación (consecución de una completa opción
                                    de
                                    sobre-asignación
                                    o green shoe).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat conduce un análisis preliminar
                                            de mercados y
                                            evalúa el alcance de emisión del cliente en el mercado accionario nacional e
                                            internacional, para
                                            desarrollar un plan de acción detallado para lograr una colocación óptima.
                                            Asimismo, IRStrat
                                            prepara una agenda de trabajo que detalla minuciosamente los pasos precisos
                                            para completar la
                                            preparación del Prospecto, así como para llevar a cabo el roadshow y la
                                            construcción del libro de
                                            ofertas.</p>
                                            <p>Nuestro equipo, de la mano de los principales ejecutivos, comienza a
                                                definir la tesis de
                                                inversión, historia y ventaja competitiva de la Compañía, así como el
                                                estilo de comunicación
                                                (mix cualitativo y cuantitativo), y una óptima estrategia de prospección
                                                y divulgación.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>Se ejecuta el targeting y beauty contest del
                                            sindicato colocador, el
                                            targeting de los inversores institucionales y retail, el roadshow nacional e
                                            internacional, y
                                            elabora un proxy del book de salida. Asimismo, se lleva a cabo un intenso
                                            lobbying con fondos de
                                            inversión y pensiones, así como presentaciones con family offices,
                                            endowments, fondos soberanos,
                                            áreas de promoción y análisis de brokerages, tesorerías, fondos mutuos de
                                            blue chips y medios
                                            financieros especializados.</p>
                                            <p>Además, IRStrat conduce un entrenamiento exhaustivo del equipo directivo
                                                de nuestro cliente en
                                                procesos de investor education, presentaciones de roadshow, reuniones
                                                cara a cara y foros
                                                especializados. Se trabaja a detalle el Prospecto, la presentación del
                                                roadshow, y se desarrolla
                                                un contundente fact sheet de la tesis de inversión y
                                                bondades del
                                                instrumento.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente coloca un monto original de US$1,000 millones, más US$200
                                            millones por el ejercicio completo de la opción de sobreasignación, con un
                                            precio de salida 35%
                                            superior a la valuación promedio de su sector, y con una participación
                                            óptima de inversionistas
                                            minoristas e institucionales. Esto último le permitió a la Compañía
                                            maximizar la liquidez de sus
                                            acciones, a la par de desarrollar una cobertura de analistas compuesta por 6
                                            instituciones, que
                                            asignaron recomendaciones superiores al mercado.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={ipo3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
